import { intersectionObserve } from "./_intersection-observer";

// Menu on scroll
const breakpoint = 900;
const header = document.querySelector('header');
let body = document.querySelector('body');
let bookDemo = document.querySelector(".book-demo-form-container");
let lastScrollTop = 0;
const elements = document.querySelectorAll('.hero');
let headerBookDemoButton = document.querySelector(".book-demo-button-mobile");
const footerLinks = document.querySelectorAll(".footer-links");

window.onscroll = function(){
    let st = window.scrollY || document.documentElement.scrollTop;
    if (st > lastScrollTop && st > 300) {
        header.classList.add("scroll-down");
        header.classList.remove("scroll-up");
    } else if (st < lastScrollTop) {
        header.classList.add("scroll-up");
        header.classList.remove("scroll-down");
    } 
    lastScrollTop = st <= 0 ? 0 : st;
};

if(bookDemo) {
    let bookDemoButton = document.querySelectorAll(".book-demo-button");
    let exitDemoButton = document.querySelector(".exit-demo-form");
    let exitDemoArea = document.querySelector(".exit-demo-form-area");
    let contactForm7Submit = bookDemo.querySelector(".wpcf7-submit");
    let bookDemoSubmit = bookDemo.querySelector(".submit-form-button");

    window.addEventListener('load', (event) => {
        intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
            (function(el) {
                if (intersectionRatio > 0) {
                    headerBookDemoButton.classList.remove('show');
                } else {
                    headerBookDemoButton.classList.add('show');
                }
            })(element);
        });

        intersectionObserve(footerLinks, 0, 0, (element, intersectionRatio) => {
            (function(el) {
                if (intersectionRatio > 0) {
                    headerBookDemoButton.classList.remove('show');
                } else {
                    headerBookDemoButton.classList.add('show');
                }
            })(element);
        });

        intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
            (function(el) {
                if (intersectionRatio > 0 && !header.classList.contains('show')) {
                    header.classList.remove('dark-logo');
                }  else {
                    header.classList.add('dark-logo');
                }
            })(element);
        });
    });

    bookDemoButton.forEach(button => {
        button.addEventListener("click", (event) => {
            if(bookDemo.classList.contains('active')) {
                turnInActive(bookDemo);
                body.style.overflow = "visible";
            } else {
                turnActive(bookDemo);
                body.style.overflow = "hidden";
            }
        });
    });

    exitDemoButton.addEventListener("click", (event) => {
        turnInActive(bookDemo);
        body.style.overflow = "visible";
    });

    exitDemoArea.addEventListener("click", (event) => {
        turnInActive(bookDemo);
        body.style.overflow = "visible";
    });

    bookDemoSubmit.addEventListener("click", (event) => {
        contactForm7Submit.click();
    });


    $(document).ready(function() {

        fixCF7MultiSubmit();

        function fixCF7MultiSubmit(){
            jQuery('input.wpcf7-submit[type="submit"]').click(function() {
                var disabled = jQuery(this).attr('data-disabled');
                if (disabled && disabled == "disabled") {
                    return false;
                } else {
                    jQuery(this).attr('data-disabled',"disabled");
                    return true;
                }
            });

            jQuery('.wpcf7').bind("wpcf7submit",function(){
                    jQuery(this).find('input.wpcf7-submit[type="submit"]').attr('data-disabled',"enabled");
            });
        }
    });
}

if(window.innerWidth > breakpoint) {
    let menu = document.querySelector(".menu-content");
    let interactiveBlub = document.querySelector(".interactive-blub");
    let menuElements = document.querySelectorAll(".menu-item");

    menuElements.forEach((element, index) => {
        element.addEventListener("mouseover", () => {
            adjustBlubToElement(element, interactiveBlub);
        });

        element.addEventListener("mouseout", () => {
            restoreWidth(menu, interactiveBlub);
        });
    });
}

function turnActive(element) {
    if(element){
        element.classList.add("active");
    }
}

function turnInActive(element) {
    if(element) {
        element.classList.remove("active");
    }
}

function adjustBlubToElement(element, blub) {
	let elementWidth = element.offsetWidth;
	let elementLeft = element.offsetLeft;
	let elementTop = element.offsetTop;

	blub.style.width = elementWidth + "px";
	blub.style.left = elementLeft + "px";
	blub.style.top = elementTop + "px";
}

function restoreWidth(partent, blub, offsetLeft) {
	let elementWidth = partent.offsetWidth;
	blub.style.width = elementWidth + "px";
    if(offsetLeft) {
        let elementLeft = partent.offsetLeft;
        blub.style.left = elementLeft+"px";
    } else {
        blub.style.left = "0px";
    }
}